import PropTypes from "prop-types"
import React, { Component, useState } from "react"

import Layout from "../components/layout/layout"
import SectionContainer from "../components/layout/sectioncontainer"

import ConnectBlock from "../components/sections/connectblock"
import BlueData from "../components/sections/bluedata"
import GridContentBlock from "../components/grid/gridcontentblock"
import SignalingFuture from "../components/sections/signaling-future"

import LoopVideo from "../images/the-loop.mp4"
import LoopLabels from "../assets/loopLabels.svg"

import Lottie from "react-lottie"
import BarGraph from "../components/lottie/bar-graph.json"

import Hero from "../components/shared/hero"

import GridPane from "../components/grid/gridpane"
import GridImage from "../components/grid/gridimage"

import { OaklandBay } from "../components/images/oakland-bay"
import { OaklandAhead1 } from "../components/images/oakland-ahead-1"
import { OaklandAhead2 } from "../components/images/oakland-ahead-2"

import AnimHeader from "../components/shared/animheader"
import SEO from "../components/seo"

import VisibilitySensor from "react-visibility-sensor"

export default class OaklandAheadPage extends Component {
  constructor({ props, transitionStatus, entry, exit }) {
    super(props)
    this.barGraph = React.createRef()

    this.signalAnimComplete = false
    this.layout = React.createRef()

    this.state = {
      bargraphpause: true,
      sensorEnabled: true,
      signalAnimComplete: false,
    }
  }

  componentDidMount() {
    this.animGraph = () => {
      this.setState({ bargraphpause: false, sensorEnabled: false })
    }

    this.toggleGallery = this.layout.current.toggleGallery
    this.toggleVideo = this.layout.current.toggleVideo
    this.navSwitchCallback = this.layout.current.navSwitchCallback
    this.progressCheckCallback = this.layout.current.progressCheckCallback
    this.layout.current.runSignalAnim(() => {
      this.setState({ signalAnimComplete: true })
    })
  }

  render() {
    const BarGraphOptions = {
      loop: false,
      autoplay: false,
      animationData: BarGraph,
    }
    return (
      <Layout ref={this.layout}>
        <SEO
          title="Oakland Rising"
          description="Innovative, dynamic businesses. Affordable, sustainable housing. Art, culture, and 21st century transit options. It’s all happening in Oakland. Find your place in the future of the Bay Area’s most diverse, artistic, liveable city."
        />

        <Hero
          pageClass="oakland-ahead"
          headerText={[`oakland`, `rising`]}
          descText="Innovative, dynamic businesses. Affordable, sustainable housing. Art, culture, and 21st century transit options. It’s all happening in Oakland.  Find your place in the future of the Bay Area’s most diverse, artistic, liveable&nbsp;city."
          locationState={this.locationState}
          navSwitchCallback={this.navSwitchCallback}
          playButton={true}
          toggleVideo={this.toggleVideo}
          oaklandRisingBtn={true}
          progressCheckCallback={this.progressCheckCallback}
        />

        {/* SECTION 1 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="20"
          id="first-section"
          inverted={false}
        >
          <AnimHeader
            typeText={[`the FOMOOO*`, `is real`]}
            descText="New commercial and residential developments—similar to the evolution of Brooklyn, Chelsea and SoMa—are driving Oakland towards a dynamic new era."
            descAlign="right"
            footNote={`*Fear Of Missing Out On Oakland`}
          />
        </SectionContainer>

        {/* SECTION 2 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="30"
          id="oakland-ahead-2"
          inverted={false}
        >
          <div className="block-container single-image">
            <div className="block-container-inner">
              <OaklandBay />
            </div>
          </div>
        </SectionContainer>

        {/* SECTION 3 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="40"
          id="oakland-ahead-3"
          inverted={true}
        >
          <BlueData dataComponent={`oakland-ahead`} />
        </SectionContainer>

        {/* SECTION 4 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="50"
          id="oakland-ahead-4"
          inverted={false}
        >
          <AnimHeader
            typeText={[`signaling`, `the future`]}
            descText="Telegraph Tower puts your company in the Bay Area’s geographic center—at a time when regional strategies are more important to your company’s growth than ever before."
            descAlign="right"
          />
        </SectionContainer>

        {/* SECTION 5 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="60"
          id="oakland-ahead-5"
          inverted={true}
        >
          <SignalingFuture footNote="data source: *Cushman & Wakefield Research & Brokerage" />
        </SectionContainer>

        {/* SECTION 6 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="70"
          id="oakland-ahead-6"
          inverted={false}
        >
          <AnimHeader
            typeText={[`where the`, `talent wants`, `to be`]}
            descText="Talent is choosing Oakland and the East Bay for better housing, lifestyle, walkability and weather. Oakland has direct access to more Bay Area neighborhoods and East Bay tech talent than San Francisco or San Jose."
            descAlign="right"
          />

          <GridPane type="grid1">
            <GridImage position="mid-left" gradientType="yellowred">
              <GridContentBlock
                contentType="quote"
                content={["The workforce is already here"]}
                fontSize="large"
              />
              <VisibilitySensor
                onChange={this.animGraph}
                active={this.state.sensorEnabled}
              >
                <Lottie
                  options={BarGraphOptions}
                  isPaused={this.state.bargraphpause}
                />
              </VisibilitySensor>
              <div className="graph-label">Number of tech workers</div>
              <div className="statement-footnote">
                data source: Cushman & Wakefield Research & Brokerage
              </div>
            </GridImage>

            <GridImage position="top-right" gradientType="blueblue">
              <GridContentBlock
                contentType="quote"
                content={[
                  "31,400 jobs added 2010 – 2018",
                  "60% increase in tech employment past decade",
                ]}
                footnote={`data source: Oaklandca Metropolitan Chamber of Commerce`}
              />
            </GridImage>
          </GridPane>
        </SectionContainer>

        {/* SECTION 8 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="70"
          id="oakland-ahead-8"
          inverted={true}
        >
          <div className="the-loop-wrapper">
            <div className={"the-loop-container"}>
              <div className="the-loop-content">
                <AnimHeader
                  buttonText={`transportation map`}
                  buttonLink={`/leasing#fact-sheets`}
                  typeText={[`center of the`, `bay area loop`]}
                  mode={`init`}
                  descText={`Telegraph Tower puts you one block away from 19th Street—a major BART transfer station and AC Transit hub—in a neighborhood with a 96 Walk Score. That means effortless transit connections to 50% of Bay Area tech talent and easy access to 30% of the entire Bay Area workforce.`}
                />
              </div>

              <div className="video-wrapper">
                <div className="video-inner">
                  <video playsInline muted={true} loop autoPlay>
                    <source src={LoopVideo} type="video/mp4" />
                  </video>
                  <LoopLabels className={`loop-labels`} />
                </div>
              </div>
            </div>
          </div>
        </SectionContainer>

        {/* SECTION 9 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          id="worklife-section-9"
          dashIncrement="80"
          inverted={false}
        >
          <AnimHeader
            typeText={[`oakland`, `advantage`]}
            descText={`Transit Centers in Silicon Valley and San Francisco are at capacity for office space and housing options. San Francisco and Silicon Valley commute times are at an all-time high. It’s the perfect time to stake your claim to the fastest emerging tech destination.`}
          />
          <GridPane type="grid1">
            <GridImage
              position="mid-left"
              textContent={false}
              gradientType="yellowblue"
            >
              <GridContentBlock
                contentType="list"
                content={[
                  "Ideal location for distributed campus strategy",
                  "Better value, more connected than SF, Palo Alto and Mountain View",
                  "The Bay Area’s next platform for large-scale growth",
                ]}
              />
            </GridImage>

            <GridImage position="top-right">
              <OaklandAhead1 />
            </GridImage>

            <GridImage position="bot-right">
              <OaklandAhead2 />
            </GridImage>
          </GridPane>
        </SectionContainer>

        {/* SECTION 10 */}
        <SectionContainer
          dashDetect={this.state.signalAnimComplete}
          navSwitchCallback={this.navSwitchCallback}
          dashIncrement="90"
          id="oakland-ahead-10"
        >
          <ConnectBlock />
        </SectionContainer>
      </Layout>
    )
  }
}
