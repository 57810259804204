import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import { TimelineMax, TweenMax, TweenLite, Linear, Power4 } from 'gsap';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import "../shared/dashbar.scss"

import NavSwitch from "./navswitch"


export default class SectionContainer extends Component {


  static propTypes = {
    section: PropTypes.string,
    dashDetec: PropTypes.bool
  }

  static defaultProps = {
    section: '',
    dashDetect: false
  }

  constructor(props) {
    super(props);
    this.containerPane = React.createRef();

    this.dash0 = React.createRef();
    this.dash1 = React.createRef();
    this.dash2 = React.createRef();
    this.dash3 = React.createRef();

    this.state = {
      height: 0,
      dashDetector: false
    }
  }

  componentDidMount() {
    const height = this.containerPane.current.clientHeight;
    this.setState({ height });

    this.dashDiv = Number(Math.round(Math.round(height/50)/10) * 2);

    let headerItems = this.containerPane.current.querySelectorAll('.dashItem');
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dashDetect && this.props.dashDetect) {
      this.setState({dashDetector: true});
    }
  }

  fireChange(progress, event) {
  }

  render() {

    let dashIncrementMin = Number(this.props.dashIncrement) + Number(this.dashDiv + 2);
    let dashIncrementMin1 = Number(this.props.dashIncrement) - Number(this.dashDiv);
    let dashIncrementMin2 = Number(this.props.dashIncrement) + Number(this.dashDiv + 4);

    let mainDash = ``;

    if (this.state.dashDetector) {

      mainDash = 
      <Controller>
        <Scene duration={this.state.height} classToggle="dashExpanded" indicators={false} loglevel="1">
          <div ref={this.dash0}>
            <div className={[`dashItem`,`main`].join(' ')}  style={{ top: `${this.props.dashIncrement}vh`, position: `fixed` }}>
            </div>
          </div>
        </Scene>
      </Controller>

    } else {
      mainDash = 
      <div ref={this.dash0} className={[`dashItem`,`signal-item`].join(' ')}  style={{ top: `${this.props.dashIncrement}vh`, position: `fixed` }}>
      </div>
    }

    return (

    <div className={"section " + this.props.section + " section-container"} ref={this.containerPane} id={this.props.id}>
      <div className={"section-container-inner"}>
      
      <Controller>

        <Scene duration={this.state.height} indicators={false} offset="-10" triggerElement={this.containerPane.current} triggerHook=".08" loglevel="1" reverse="false">
        {(progress, event) => (
          <NavSwitch event={event.type} progress={progress} inverted={this.props.inverted} callback={this.props.navSwitchCallback} />
        )}
        </Scene>

      </Controller>

      {mainDash}

      <div ref={this.dash1} className={[`dashItem`,`signal-item`].join(' ')}  style={{ top: `${dashIncrementMin}vh`, position: `fixed`, height: `2px` }}>
      </div>

      <div ref={this.dash2} className={[`dashItem`,`signal-item`].join(' ')}  style={{ top: `${dashIncrementMin1}vh`, position: `fixed`, height: `2px` }}>
      </div>
      <div ref={this.dash3} className={[`dashItem`,`signal-item`].join(' ')}  style={{ top: `${dashIncrementMin2}vh`, position: `fixed`, height: `1px` }}>
      </div>

      {this.props.children}

      </div>

    </div>
    )
  }

}
