import Link from "./customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import ScrollMagic from 'ScrollMagic'

import VisibilitySensor from 'react-visibility-sensor'

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";
import ArrowWhite from "../../assets/arrow-right-light.svg";

import { Parallax } from 'react-scroll-parallax';

import "./animheader.scss";

export default class AnimHeader extends Component {
  
  static propTypes = {
    typeText: PropTypes.array,
    titleAlign: PropTypes.string,
    descAlign: PropTypes.string,
    descText: PropTypes.string,
    descList: PropTypes.array,
    fullLink: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    buttonClass: PropTypes.string,
    mode: PropTypes.string,
    startAnim: PropTypes.bool,
    footNote: PropTypes.string,
    locationState: PropTypes.string,
    animateCursor: PropTypes.bool,
    isHome: PropTypes.bool,
    oaklandRisingBtn: PropTypes.bool
  }

  static defaultProps = {
    typeText: [],
    descList: [],
    titleAlign: `left`,
    descAlign: `left`,
    descText: ``,
    mode: `scroll`,
    startAnim: false,
    footNote: '',
    buttonClass: '',
    animateCursor: false,
    isHome: false,
    oaklandRisingBtn: false
  }

  constructor(props) {
    super(props)

    this.state = {
      'sensorEnabled': true,
      'buttonClass': `btn`,
      'arrowClass': ``
    }

    this.cursorEle = React.createRef();
    this.descSpan = React.createRef();
    this.ctaButton = React.createRef();
    this.linkArrow = React.createRef();
    this.typeHeader = React.createRef();
    this.descTextEle = React.createRef();
    this.headerEyeBrow = React.createRef();
  }

  componentDidMount() {

    var _self = this;

    this.hasButton = this.hasArrow = this.hasEyeBrow = false;

    if (this.props.oaklandRisingBtn) {
      this.hasButton = true;
    }

    if (this.props.buttonLink && this.props.buttonText) {
      this.hasButton = true;
    }

    if (this.props.arrowLink) {
      this.hasArrow = true;
    }

    if (this.props.eyeBrowText) {
      this.hasEyeBrow = true;
    }
  
    // set some defaults
    TweenMax.set(this.cursorEle.current, { opacity: 0 });
    if (this.hasButton) TweenMax.set(this.ctaButton.current, { opacity: 0 });

    if (this.props.inverted) {
      this.state.buttonClass = `btn btn-inverted`;
    }
    if (this.props.buttonClass != ''){
      this.state.buttonClass = `btn ` + this.props.buttonClass;
    }

    let headerItems = this.typeHeader.current.querySelectorAll('.typeSpan');

    this.textAnim = () => {

      let tl = new TimelineMax();

      tl.staggerTo(headerItems, .8, {ease: Power4.easeOut, opacity: 1, y: 0,}, 0.2);
      
      let descDelay = '-=1.2';
      let altDelay = '-=.7';
      if (this.props.animateCursor) {
        tl.to(_self.cursorEle.current, .5, { opacity: 1, repeat: 4, yoyo: true}, '-=.1');
        descDelay = '-=2.9';
        altDelay = '-=2.4';
      } else {
        tl.to(_self.cursorEle.current, .5, { opacity: 1 }, '-=.1');
      }

      if(this.props.descText != '' && this.props.descList != []) tl.to(_self.descSpan.current, .8, {ease: Power4.easeOut, opacity: 1, x: 0,}, descDelay);
      // conditional elements
      if (this.hasButton) tl.to(_self.ctaButton.current, 2, {ease: Power4.easeOut, opacity: 1, }, altDelay);
      if (this.hasEyeBrow) tl.to(this.headerEyeBrow.current, 1.2, {ease: Power4.easeOut, opacity: 1 }, altDelay );

      tl.play();

      this.setState({'sensorEnabled': false})
    }

    // init triggers animation manually without visibility trigger
    if (this.props.mode == 'init') {
      this.state.sensorEnabled = false;
      window.setTimeout(this.textAnim, 1000);
    }

    // suspend awaits a start anim prop
    if (this.props.mode == 'suspend') {
      this.state.sensorEnabled = false;
    }

    // no anim sets elements to end animation states
    if (this.props.mode == 'no-anim') {

      TweenMax.set(headerItems, {ease: Power4.easeOut, opacity: 1, y: 0,});
      TweenMax.to(_self.cursorEle.current, .5, { opacity: 1 });
      if(this.props.descText != '' && this.props.descList != []) TweenMax.set(_self.descSpan.current, {ease: Power4.easeOut, opacity: 1, x: 0,});

      // conditional elements
      if (this.hasButton) TweenMax.set(_self.ctaButton.current, {ease: Power4.easeOut, opacity: 1, });
      if (this.hasEyeBrow) TweenMax.set(_self.headerEyeBrow.current, {ease: Power4.easeOut, opacity: 1 });

    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.startAnim && this.props.startAnim && this.props.mode == 'suspend') {
      this.textAnim();
    }
  }

  headerHoverOn = () => {
    if (this.props.arrowLink && this.props.startAnim) {
      TweenMax.to(this.linkArrow.current, .5, { opacity: 1 });
    }

  }

  headerHoverOff = () => {
    if (this.props.arrowLink && this.props.startAnim) {
      TweenMax.to(this.linkArrow.current, .5, { opacity: 0 });
    }

  }

  headerClick = () => {
    if (this.props.arrowLink) {
      TweenMax.set(this.descTextEle.current, {overflow: 'visible'});
      this.setState({arrowClass: `active`})
      TweenMax.to(this.linkArrow.current, 1, { x: 50, opacity: 0 });
    }   
  }

  descriptionOutput = () => {
    if (this.props.descText != ''){
      return this.props.descText;
    }else {
      return <div className="content-list">
          <ul>
          {this.props.descList.map((value, index) => (
            <li className="content-list-level-1" key={index}>
            {value[0]}
            {value[1] &&
              <ul className="test">

                {value[1].map((value_2, index_2) => (
                  <li className="content-list-level-2" key={index_2}>
                    {value_2}
                  </li>
                ))}
              </ul>
            }
            </li>
          ))}
          </ul>
        </div>
    }
  }

  render() {

    let headerRender = ``;
    let headerButton = ``;
    let headerEyeBrow = ``;

    if (this.props.isHome){
      headerButton =  
        <div><Link to={this.props.buttonLink} locationState={this.locationState} className={this.state.buttonClass}>{this.props.buttonText}</Link>
        <div className={`arrowLink ${this.state.arrowClass}`} ref={this.linkArrow}><ArrowWhite /></div></div>;
    }else if (this.props.oaklandRisingBtn){
      headerButton = <span onClick={this.props.toggleVideo} className={this.state.buttonClass + ` btn-inverted cta-desktop-hidden`}>See Oakland Rising</span>
    }else if (this.hasButton) {
      headerButton =  
        <Link to={this.props.buttonLink} locationState={this.locationState} className={this.state.buttonClass}>{this.props.buttonText}</Link>
    } else {
      headerButton = <div className={`arrowLink ${this.state.arrowClass}`} ref={this.linkArrow}><ArrowWhite /></div>;
    }

    if (this.props.eyeBrowText) {
      headerEyeBrow =  
        <span className="header-eyebrow" ref={this.headerEyeBrow}>{this.props.eyeBrowText}</span>
    }

    const headerInner = 
      <div className={`typeHeader-container`} onMouseEnter={this.headerHoverOn} onMouseLeave={this.headerHoverOff} onClick={this.headerClick}>

      <h1 className={`typeHeader ${this.props.titleAlign}`}  ref={this.typeHeader}>
          {headerEyeBrow}

          {this.props.typeText.map((value, index) => (
            <span className="textWrap" key={index}>
              <span className="typeSpanProxy" >{value}</span>
              <span className="typeSpan" >{value}</span>
            </span>
          ))}
        <span className="header-cursor" ref={this.cursorEle}></span>
      </h1>

      {(this.props.descText != '' && this.props.descList != []) &&
        <div className={`descText ${this.props.descAlign}`} ref={this.descTextEle}>

          <span className="descSpan" ref={this.descSpan}>{this.descriptionOutput()}</span>
          <span className="descSpanProxy">{this.descriptionOutput()}</span>   

          <div className="animheader-footnote">{this.props.footNote}</div>  
          
          <div ref={this.ctaButton}>
            {headerButton}
          </div>

        </div>
      } 
      </div>;

      if (this.props.fullLink) {
        headerRender = 
            <Link to={this.props.fullLink}>
            {headerInner}
            </Link>;
      } else {
        headerRender = headerInner;
      }

    return (

      <VisibilitySensor onChange={this.textAnim} active={this.state.sensorEnabled}>

      <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
      {headerRender}
      </Parallax>

      </VisibilitySensor>

    )
  }

}
