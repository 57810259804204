import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import "./gridpane.scss"

export default class GridImage extends Component {

  static propTypes = {
    position: PropTypes.string,
    gradientType: PropTypes.string,
    textContent: PropTypes.bool,
    noHover: PropTypes.string,
    clickable: PropTypes.string
  }

  static defaultProps = {
    position: 'top-left',
    position: 'default',
    textContent: false,
    noHover: '',
    clickable: ''
  }

  constructor(props) {
    super(props);
    this.containerPane = React.createRef();

    this.posStyle = "topLeft";

    switch (this.props.position) {
      case 'mid-left': {
        this.posStyle = "midLeft";
        break;
      }
      case 'mid-right': {
        this.posStyle = "midRight";
        break;
      }
      case 'top-left': {
        this.posStyle = "topLeft";
        break;
      }
      case 'bot-left': {
        this.posStyle = "botLeft";
        break;
      }
      case 'top-right': {
        this.posStyle = "topRight";
        break;
      }
      case 'bot-right': {
        this.posStyle = "botRight";
        break;
      }
      case 'top-right-full': {
        this.posStyle = "topRightFull";
        break;
      }
    }

    if (this.props.textContent) {
      this.contentType = "textContent";
    }
  }

  componentDidMount() {

    /*const shapeTween = new TimelineLite()
    shapeTween.to(deviceMobile, 1, {ease: Power4.easeOut, opacity: 1, x: 0});
    shapeTween.to(deviceTablet, 1, {ease: Power4.easeOut, opacity: 1, x: 0}, '-=.9');
    shapeTween.to(captions, 1, {ease: Power4.easeOut, opacity: 1, y: 0}, '-=.9');*/
  }

  render() {
    return (
    <div onClick={this.props.clickHandler} className={"gridImageItem " + this.posStyle + " " + this.contentType+ " " + this.props.clickable + " " + this.props.gradientType + " " + this.props.noHover} ref={this.containerPane}>

      {this.props.children}

    </div>
    )
  }

}
