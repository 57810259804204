import Link from "../shared/customlink.js"
// import PropTypes from "prop-types"
import React, { Component } from 'react'

import AnimHeader from "../shared/animheader"

import CushmanLogo from "../../assets/cushman-wakefield-logo-small.svg";
import JllLogo from "../../assets/jll-logo-small.svg";

import "./connectblock.scss"


export default class ConnectBlock extends Component {

  componentDidMount() {

  }

  render() {

    return (
    <div className="block-container" >
      <div className="block-container-inner">
        <AnimHeader typeText={[`connect with our team`]} titleAlign="center" />
        <div className="connect-contacts">
          <div className="content-row">
            <div className="content-col col-1">
              <div className="col-inner">
                <div className="name-header heading">Christopher Roeder</div>
                <div className="contact-info"><a href="mailto:chris.roeder@am.jll.com">chris.roeder@am.jll.com</a> | 415.395.4971</div>
                <JllLogo />
              </div>
            </div>
            <div className="content-col col-2">
              <div className="col-inner">
                <div className="name-header heading">JD Lumpkin</div>
                <div className="contact-info"><a href="mailto:jd.lumpkin@cushwake.com">jd.lumpkin@cushwake.com</a> | 415.658.3648</div>
                <CushmanLogo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

}
