import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import "./gridpane.scss"

export default class GridContentBlock extends Component {

  static propTypes = {
    content: PropTypes.array,
    contentType: PropTypes.string,
    footnote: PropTypes.string,
    fontSize: PropTypes.string 
  }

  static defaultProps = {
    content: [],
    contentType: 'list',
    footnote: '',
    fontSize: 'small'
  }

  constructor(props) {
    super(props);
    this.containerPane = React.createRef();
  }

  componentDidMount() {

  }

  render() {
    const ConditionalWrapper = ({ condition, wrapper, children }) => 
      condition ? wrapper(children) : children;

    return (
    <div className={"contentblockPane type-" + this.props.contentType} ref={this.containerPane}>

      {this.props.content.map((value, index) => (
          <div key={index} className="listItem">
            <ConditionalWrapper
              condition= {this.props.fontSize == 'large'}
              wrapper= {children => <h3>{children}</h3>}
              >
              {value}
            </ConditionalWrapper> 
          </div>
      ))}
      {this.props.footnote != '' &&
        <div className="statement-footnote">{this.props.footnote}</div>
      }

    </div>
    )
  }
}
