import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'


export default class NavSwitch extends Component {


  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) { 
    if (this.props.event == "enter") {

      if (this.props.callback) {
        this.props.callback(this.props.inverted);
      }
    }

    if (this.props.progressCheck) {
      this.props.callback(this.props.progress);
    }
  }

  render() {

    return (null)
  }

}
