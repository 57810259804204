import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import VisibilitySensor from 'react-visibility-sensor'

import { ImgSignalingFuture } from '../images/signaling-future';
import { ImgSignalingFutureRes } from '../images/signaling-future-res';
import { ImgSignalingFutureCom } from '../images/signaling-future-com';

import Lottie from 'react-lottie'
import TowerFilter from '../lottie/tower-filter.json'

import "./signaling-future.scss"


export default class SignalingFuture extends Component {

  static propTypes = {
    footNote: PropTypes.string
  }

  static defaultProps = {
    footNote: ''
  }

  constructor (props) {
    super(props);

    this.state = {
      residential: ' active',
      commercial: ' active',
      isPaused: true,
      towerFilterDirection: 1,
      animationRunning: false
    }
  }

  componentDidMount() {
    this.signalingFuture = React.createRef();
    this.signalingFutureAnimation = React.createRef();

    this.setState({residential:' active', commercial:' active', isPaused: true, towerFilterDirection:1})
  }

  toggleRes = () => {
    if(this.state.residential == ''){
      this.setState({residential:' active'})
    }else {
      this.setState({residential:''})
    }
  }

  toggleComm = () => {
    if(this.state.commercial == ''){
      this.setState({commercial:' active'})
    }else {
      this.setState({commercial:''})
    }
  }

  towerAnimAction = () => {
    
    if(this.signalingFutureAnimation && !this.state.animationRunning){
      this.setState({animationRunning: true, isPaused: false})
      this.signalingFutureAnimation.current.anim.loop = false;
      this.signalingFutureAnimation.current.anim.playSegments([0, 150], true);
      this.signalingFutureAnimation.current.anim.addEventListener('complete', this.startLoop);
    }

  }

  startLoop = () => {
    var _self = this;
    this.signalingFutureAnimation.current.anim.loop = true;
    this.signalingFutureAnimation.current.anim.removeEventListener('complete', _self)
    this.signalingFutureAnimation.current.anim.playSegments([150, 200], true);
  }
  render() {

    const towerFilterOpts = {
      loop: false,
      autoplay: false, 
      animationData: TowerFilter
    };  

    return (
      <div>
        <VisibilitySensor onChange={this.towerAnimAction} active={this.state.sensorEnabled}>
          <div className="signaling-future-img-wrapper">
            <div className="full-width-img base">
              <ImgSignalingFuture />
            </div>
            <div className={`full-width-img residential` + this.state.residential}>
              <ImgSignalingFutureRes />
            </div>
            <div className={`full-width-img commercial` + this.state.commercial}>
              <ImgSignalingFutureCom />
            </div>
            <div className="full-width-img tower-animation">
              <Lottie 
                ref={this.signalingFutureAnimation} 
                options={towerFilterOpts} 
                width={`100%`} 
                height={`100%`}
                isPaused={this.state.isPaused}  
                direction={this.state.towerFilterDirection}/>
              }
              }
            </div>
          </div>
        </VisibilitySensor>
        <div className="signaling-future-wrapper" ref={this.signalingFuture}>
          <div className="signaling-future-helper">
            <div className="signaling-future-helper-label">
              Toggle Checkboxes to Filter
            </div>
          </div>
          <div className="block-container signaling-future-container" >
            <div className="block-container-inner">
              <div className="signaling-future-content">  
                <div className="filters">
                  <div className="filters-inner">
                    <div className="filter filter-residential">
                      <div className="filter-wrapper">
                        <label className={`filter-residential` + this.state.residential}>residential
                          <input
                            type="checkbox"
                            value="Residential"
                            onChange={this.toggleRes}
                            id="filter-residential"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="filter-data">
                        <div className="filter-data-inner">
                          <div className="filter-data-row row-1">
                            <div className="filter-data-col col-1">Year</div>
                            <div className="filter-data-col col-2">2019</div>
                            <div className="filter-data-col col-3">2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter filter-commercial">
                      <div className="filter-wrapper">
                        <label className={`filter-commercial` + this.state.commercial}>commercial
                          <input
                            type="checkbox"
                            value="Commercial"
                            onChange={this.toggleComm}
                            id="filter-commercial"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="filter-data">
                        <div className="filter-data-inner">
                          <div className="filter-data-row row-1">
                            <div className="filter-data-col col-1">Year</div>
                            <div className="filter-data-col col-2">2019</div>
                            <div className="filter-data-col col-3">2025</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
