import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import VisibilitySensor from 'react-visibility-sensor'

import AnimHeader from "../shared/animheader"

import Lottie from 'react-lottie'
import LineGraph from '../lottie/line-graph.json'

import "./bluedata.scss"

export default class BlueData extends Component {

  static propTypes = {
    descText: PropTypes.string,
    titleData: PropTypes.array,
    titleAlign: PropTypes.string,
    dataComponent: PropTypes.string
  }

  static defaultProps = {
    descText: '',
    titleData: [],
    titleAlign: '',
    dataComponent: ''
  }

  constructor (props) {
    super(props);

    this.state = {
      linegraphpause: true,
      sensorEnabled: true
    }
  }

  componentDidMount() {
    var _self = this;
    this.blueData = React.createRef();
    
    this.animGraph = () => {
      this.setState({linegraphpause:false, sensorEnabled: false})
    }

  }

  renderContent = (data) => {
    const LineGraphOptions = {
      loop: false,
      autoplay: false, 
      animationData: LineGraph
    };
    switch(data) {
      case 'all_signals':
        return <div>
                  <p>CHART GOES HERE</p>
                  <Link to='/' className='btn btn-inverted'>Look Ahead</Link>
                  <div className="chart-footnote">*data source: Cushman & Wakefield Research & Brokerage  |  Moody’s Data Buffet  |  CoStar</div>
                </div>;
      case 'savings':
        return  <div>
                  <p>Table Layout</p>
                </div>;
      case 'oakland-ahead':
        return  <div>
                  <div className="chart-grid">
                    <div className="chart-grid-col">
                      <span className="label">$<span className="number">4.3</span>BN</span>
                      <p>Invested in Commercial and Residential Real Estate in the last five years*</p>
                    </div>
                    <div className="chart-grid-col">
                      <span className="label"><span className="number">9,000</span>+</span>
                      <p>New homes to be delivered in the next two years (within 10 min of the site)**</p>
                    </div>
                    <div className="chart-grid-col">
                      <span className="label">+<span className="number">24,000</span></span>
                      <p>Projected 5-year population growth**</p>
                    </div>
                  </div>
                  <VisibilitySensor onChange={this.animGraph} active={this.state.sensorEnabled}>
                    <Lottie options={LineGraphOptions} isPaused={this.state.linegraphpause}/>
                  </VisibilitySensor>
                  <div className="statement-footnote">data source: *Real Capital Analytics  |  **oaklandca.gov  |  †Cushman & Wakefield Research & Brokerage</div>
                </div>;
      default:
        return null;
    }

  }

  render() {

    return (
      <div className={`blue-data-wrapper ` + this.props.dataComponent} ref={this.blueData}>

      {this.props.titleData != '' &&
        <AnimHeader typeText={this.props.titleData} descText={this.props.descText} titleAlign={this.props.titleAlign} />
      }
        <div className="block-container blue-data-container">
          <div className="block-container-inner">
            <div className="blue-data-content">  
             {this.renderContent(this.props.dataComponent)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
