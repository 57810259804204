import Link from "./customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import AnimHeader from "./animheader"
import NavSwitch from "../layout/navswitch"

import Lottie from 'react-lottie'
import ArrowScroll from '../lottie/arrow-to-pointer.json'

import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { Controller, Scene } from 'react-scrollmagic';
import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";

import Playhead from "../../assets/playhead.svg"

import "../herosplit.scss"

export default class Hero extends Component {

  static propTypes = {
    pageClass: PropTypes.string,
    headerText: PropTypes.array,
    descText: PropTypes.string,
    eyeBrowText: PropTypes.string,
    oaklandRisingBtn: PropTypes.bool
  }

  static defaultProps = {
    pageClass: ``,
    headerText: [],
    descText: ``,
    oaklandRisingBtn: false
  }

  constructor(props) {
    super(props);
    this.containerPane = React.createRef();
    this.arrowIndicator = React.createRef();
    this.heroContainer = React.createRef();

    this.state = {
      direction: 1,
      isPaused:true,
      height: 0
    }

    this.headerState = 'init';

    if (this.props.locationState) {
      if (this.props.locationState.fromHome) {
        this.headerState = 'no-anim'
      }
    }
  }

  arrowAnimationPlay = () => {
    this.setState({direction:1, isPaused:false})
  }

  arrowAnimationReset = () => {
    this.setState({direction:-1})
  }

  scrollToElement = () => {
    var scrollElement = document.getElementById('first-section')
    if (this.props.pageClass == 'leasing') {
      scrollElement = document.getElementById('leasing-section-2')
    }
    scrollElement.scrollIntoView({ behavior: 'smooth' })
  }

  componentDidMount() {
    this.setState({direction:1, isPaused:true})

    const height = this.heroContainer.current.clientHeight;
    this.setState({ height });

    TweenMax.to(this.arrowIndicator.current, .5, { y: 10, repeat:-1, yoyo:true });
  }

  render() {

    const ArrowScrollOptions = {
      loop: false,
      autoplay: false, 
      animationData: ArrowScroll,
      direction: this.state.direction
    };

    return (
    <div className={'hero-split-container'} ref={this.heroContainer}>

      <div className={['hero-split-container__panefull', this.props.pageClass].join(' ')}>

        <div className={'hero-split-container__paneheader'}>
        <AnimHeader typeText={this.props.headerText} animateCursor={true} eyeBrowText={this.props.eyeBrowText} mode={this.headerState} descText={this.props.descText} arrowLink={false} oaklandRisingBtn={this.props.oaklandRisingBtn} toggleVideo={this.props.toggleVideo}/>
        </div>

      </div>

      {this.props.playButton && 
        <div className="inline-play-button" onClick={this.props.toggleVideo}>
          <Playhead />
        </div>
      }

      <div className={'arrowindicator'} ref={this.arrowIndicator}         
        onMouseEnter={this.arrowAnimationPlay}
        onMouseLeave={this.arrowAnimationReset}
        onClick={this.scrollToElement}  >
        <Lottie options={ArrowScrollOptions} 
        height={44} 
        width={44} 
        direction={this.state.direction}
        isPaused={this.state.isPaused}
        />
      </div>

      <Controller>
        <Scene duration={this.state.height} indicators={false} offset="0" triggerElement={this.heroContainer.current} triggerHook="0" loglevel="1" reverse="true">
        {(progress, event) => (
          <div>
          <NavSwitch event={event.type} progress={progress} inverted={true} callback={this.props.navSwitchCallback} />
          {this.props.progressCheckCallback && 
          (<NavSwitch event={event.type} progress={progress} inverted={true} progressCheck={true} callback={this.props.progressCheckCallback} />)
          }
          </div>
        )}
        </Scene>
      </Controller>

    </div>
    )
  }

}
