import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";

import VisibilitySensor from 'react-visibility-sensor'

import { Controller, Scene } from 'react-scrollmagic';
import NavSwitch from "../layout/navswitch"


import "./gridpane.scss"


export default class GridPane extends Component {

  static propTypes = {
    type: PropTypes.string,
  }

  static defaultProps = {
    type: '',
  }

  constructor(props) {
    super(props);

    this.containerPane = React.createRef();

    this.gridStyle = "container__inner";

    switch (this.props.type) {
      case 'grid1': {
        this.gridStyle = "grid1";
        break;
      }
      case 'grid2': {
        this.gridStyle = "grid2";
        break;
      }
      case 'grid3': {
        this.gridStyle = "grid3";
        break;
      }
    }
  }

  componentDidMount() {

    var _self = this;
    const height = this.containerPane.current.clientHeight;
    this.setState({ height });

    this.gridNodes = [];

    // console.log(this.refs)

    Object.keys(this.refs).forEach(function (item) {

      let gridItem = _self.refs[item];
      let gridDomItem = ReactDOM.findDOMNode(gridItem);
      let gridPosition = gridItem.props.position;

      if (gridPosition.includes('left')) {
        TweenMax.set(gridDomItem, { x: '-100%', opacity: 0});
      } else if (gridPosition.includes('right')) {
        TweenMax.set(gridDomItem, { x: '100%', opacity: 0});
      }

      _self.gridNodes.push(gridItem);
    });


    this.gridAnim = () => {

      let currDelay = 0;

      this.gridNodes.forEach(function (gridItem) {

        let gridDomItem = ReactDOM.findDOMNode(gridItem);
        let gridPosition = gridItem.props.position;

        let currDelay = Number(currDelay + .5)

        if (gridPosition.includes('left')) {
          TweenMax.to(gridDomItem, 1.5, { ease: Power4.easeOut, x: '0%', opacity: 1, delay: currDelay});
        } else if (gridPosition.includes('right')) {
          TweenMax.to(gridDomItem, 1.5, { ease: Power4.easeOut, x: '0%', opacity: 1, delay: currDelay});
        }
      });   
    }
  }

  render() {
    return (
    <div>
      <div className={"grid-container" + ' ' + this.gridStyle} ref={this.containerPane} >
        <Controller>
        <Scene indicators={false} triggerElement={this.containerPane.current} triggerHook=".8" loglevel="1" reverse="false">
        {(progress, event) => (
          <NavSwitch event={event.type} progress={progress} callback={this.gridAnim} />
        )}
        </Scene>
        </Controller>

        {React.Children.map(this.props.children, (element, idx) => {
          return React.cloneElement(element, { ref: idx });
        })}

      </div>
    </div>
    )
  }

}
